import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { CountryCode, DeliveryTypeEnum } from 'teddly-sdk';
import tw, { styled } from 'twin.macro';
import style from '../../components/style.module.scss';
import { AddressInput } from '@app/user/address';
import { TextField as TextFieldStripe } from '@mui/material';
import { FormSubHeading } from '@components/Atoms/Typography';
import { NewPaymentDetails } from '@interfaces';
import StateSelectDropdown from '../../components/StateSelectDropdown';
import { IAddress } from 'teddly-sdk/lib/api/Checkout/types';

// import { format } from '@utils/format';

import { useCheckoutShippingAddressContext } from '@context/CheckoutShippingAddressContext';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
} from '@stripe/react-stripe-js';
import { StripeCardElement, StripeCardNumberElement } from '@stripe/stripe-js';
import StripeInput from '../../utils/stripeInput';
import AutocompleteAddress from '../../components/Address/AutocompleteAddress';
import { getDefaultIphone } from '@utils';
import {
  Switch,
  Dialog,
  Button,
  InputBase,
} from '@teddly/teddly-ui-components';
import styles from '@styles/form.module.scss';
import {
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
} from '@config/validation';
import { useCheckoutFlowContext } from '@context/CheckoutFlowContext';
import { AddPaymentMethodDialogIDs } from '@cypress/components/dialogs/AddPaymentMethod.cy';

enum PaymentFieldNames {
  CARD_NUMBER = 'cardNumber',
  EXPIRY_DATE = 'expiryDate',
  CVV = 'cvv',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  STREET_ADDRESS1 = 'streetAddress1',
  STREET_ADDRESS2 = 'streetAddress2',
  POSTAL_CODE = 'postalCode',
}

type InputType = NewPaymentDetails & IAddress;

type PaymentDetailsProps = {
  paymentDetails?: InputType;
  onClose?: () => void;
  isOpen?: boolean;
  onSubmit?: (data: {
    card?: StripeCardElement | StripeCardNumberElement;
    cardInputData?: NewPaymentDetails;
    billingAddress?: IAddress;
  }) => void;
  loading: boolean;
  testIds: AddPaymentMethodDialogIDs;
};

const emptyPaymentDetails: InputType = {
  cardNumber: '',
  cvv: '',
  expiryDate: '',
  firstName: '',
  lastName: '',
  city: '',
  streetAddress1: '',
  postalCode: '',
  streetAddress2: '',
  country: {
    code: CountryCode.US,
    country: 'United States of America',
  },
  countryArea: '',
};
type ValidStripe = {
  cardNumber: boolean;
  expiryDate: boolean;
  cvv: boolean;
};

export default function StripePaymentDetailsForm({
  paymentDetails,
  onClose,
  isOpen = false,
  onSubmit = () => null,
  loading = false,
  testIds,
}: PaymentDetailsProps) {
  const text = 'Add';
  const { selectedShippingAddress, isCheckoutShippingAddressValid } =
    useCheckoutShippingAddressContext();
  const { checkout } = useCheckoutFlowContext();
  const [isAddressSame, setIsAddressSame] = useState<boolean>(false);
  const {
    register,
    unregister,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { isValid },
  } = useForm<InputType>({ mode: 'onChange' });

  const [countryArea, setCountryArea] = useState(
    emptyPaymentDetails.countryArea,
  );

  const cardNumberRef = useRef();

  const [stripeValidation, setStripeValidation] = useState<ValidStripe>({
    cardNumber: false,
    expiryDate: false,
    cvv: false,
  });
  const elements = useElements();

  const onStripeChange = (e, type: string) => {
    setStripeValidation((prevState) => ({
      ...prevState,
      [type]: e.complete,
    }));
  };

  const isStripeValid = Object.values(stripeValidation).every(
    (item: boolean) => {
      return item == true;
    },
  );

  useEffect(() => {
    if (isAddressSame) {
      unregister('city');
      unregister('streetAddress1');
      unregister('postalCode');
      unregister('streetAddress2');
      unregister('country');
      unregister('countryArea');
      unregister('phone');
    }
  }, [isAddressSame, isCheckoutShippingAddressValid]);

  useEffect(() => {
    if (
      !isAddressSame &&
      isCheckoutShippingAddressValid &&
      checkout.deliveryType == DeliveryTypeEnum.SHIPPING
    ) {
      setIsAddressSame(isCheckoutShippingAddressValid);
    }
  }, [isCheckoutShippingAddressValid]);

  const handleClose = () => {
    reset(emptyPaymentDetails);
    onClose();
  };
  const onSelectAddress = (selectAddressInput: AddressInput) => {
    setValue('postalCode', selectAddressInput.postalCode, {
      shouldValidate: true,
    });
    setValue('streetAddress1', selectAddressInput?.streetAddress1, {
      shouldValidate: true,
    });
    setValue('city', selectAddressInput.city, { shouldValidate: true });
    setCountryArea(selectAddressInput.countryArea);
  };

  const getFormSection = (section: JSX.Element, toLeft?: boolean) => (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: toLeft ? 'left' : 'center',
        gap: '1.5rem',
      }}
      // css={{
      //   ...tw`gap-6 grid grid-cols-1 tablet:flex flex-wrap justify-center 	`,
      // }}
    >
      {section}
    </div>
  );

  const Submit = (data: InputType) => {
    const billingAddress = isAddressSame
      ? {
          firstName: selectedShippingAddress.firstName,
          lastName: selectedShippingAddress.lastName,
          city: selectedShippingAddress.city,
          streetAddress1: selectedShippingAddress.streetAddress1,
          postalCode: selectedShippingAddress.postalCode,
          streetAddress2: selectedShippingAddress.streetAddress2,
          phone: selectedShippingAddress?.phone,
          country: {
            code: CountryCode.US,
            country: 'United States of America',
          },
          countryArea: selectedShippingAddress.countryArea,
        }
      : {
          firstName: data.firstName,
          lastName: data.lastName,
          city: data.city,
          streetAddress1: data.streetAddress1,
          postalCode: data.postalCode,
          streetAddress2: data.streetAddress2,
          phone: data?.phone,
          country: {
            code: CountryCode.US,
            country: 'United States of America',
          },
          countryArea,
        };

    const number = elements.getElement(PaymentFieldNames.CARD_NUMBER);
    onSubmit({
      card: number,
      billingAddress: billingAddress,
    });

    reset(emptyPaymentDetails);
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={`${text} Payment Methods`}
      onClose={handleClose}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      closeButtonTestId={testIds.closeBtnID}
      headerTestId={testIds.headerID}
      footerProps={{
        testId: testIds.footerID,
        children: [
          <>
            <Button
              testId={testIds.addCardSubmitBtnIDs}
              disabled={
                loading ||
                !isValid ||
                (!isAddressSame && !countryArea) ||
                !isStripeValid
              }
              loading={loading}
              type="submit"
              onClick={handleSubmit(Submit)}
              title={`${text} Card`}
            />
          </>,
        ],
      }}>
      <form onSubmit={handleSubmit(Submit)}>
        <div
          style={{ maxWidth: '800px' }}
          css={{
            ...tw`gap-6 grid grid-cols-1 tablet:flex  flex-wrap justify-center	`,
          }}>
          {getFormSection(
            <>
              <Controller
                name={PaymentFieldNames.FIRST_NAME}
                control={control}
                rules={{
                  maxLength: {
                    value: FIRST_NAME_MAX_LENGTH,
                    message: `First name is more than ${FIRST_NAME_MAX_LENGTH} Characters`,
                  },
                  required: 'First name is required!',
                }}
                defaultValue={paymentDetails?.firstName}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputBase
                    fullWidth
                    testId={testIds.firstNameInputID}
                    onChange={onChange}
                    value={value}
                    name={PaymentFieldNames.FIRST_NAME}
                    label="First Name"
                    footnote={error?.message}
                    errored={error?.message}
                  />
                )}
              />
              <Controller
                name={PaymentFieldNames.LAST_NAME}
                control={control}
                rules={{
                  maxLength: {
                    value: LAST_NAME_MAX_LENGTH,
                    message: `Last name is more then ${LAST_NAME_MAX_LENGTH} Characters`,
                  },
                  required: 'Last name is required!',
                }}
                defaultValue={paymentDetails?.lastName}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputBase
                    fullWidth
                    testId={testIds.lastNameInputID}
                    onChange={onChange}
                    value={value}
                    name={PaymentFieldNames.LAST_NAME}
                    label="Last Name"
                    footnote={error?.message}
                    errored={error?.message}
                  />
                )}
              />
            </>,
          )}
          {getFormSection(
            <>
              <TextFieldStripe
                ref={cardNumberRef}
                fullWidth
                variant="outlined"
                data-testid={testIds.cardNumberInputID}
                name={PaymentFieldNames.CARD_NUMBER}
                required={true}
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardNumberElement,
                    onChange: (e) =>
                      onStripeChange(e, PaymentFieldNames.CARD_NUMBER),
                  },
                }}
              />
              <TextFieldStripe
                name={PaymentFieldNames.EXPIRY_DATE}
                variant="outlined"
                data-testid={testIds.expirationInputID}
                css={tw`w-[300px]	tablet:w-[170px] `}
                required={true}
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardExpiryElement,
                    onChange: (e) => {
                      onStripeChange(e, PaymentFieldNames.EXPIRY_DATE);
                    },
                  },
                }}
              />
              <TextFieldStripe
                name={PaymentFieldNames.CVV}
                data-testid={testIds.cvvInputID}
                variant="outlined"
                required={true}
                css={tw`w-[300px]	tablet:w-[170px] `}
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardCvcElement,
                    onChange: (e) => onStripeChange(e, PaymentFieldNames.CVV),
                  },
                }}
              />
            </>,
          )}
          {isCheckoutShippingAddressValid &&
            checkout.deliveryType == DeliveryTypeEnum.SHIPPING && (
              <div className={styles.line}>
                <Switch
                  id="isAddressSame"
                  label="Billing address same as shipping"
                  checked={isAddressSame}
                  testId={testIds.billingAddressToggleID}
                  onChange={() => setIsAddressSame(!isAddressSame)}
                />
              </div>
            )}
          {(!isAddressSame || !isCheckoutShippingAddressValid) && (
            <>
              <div
                css={{
                  ...tw`gap-6 grid grid-cols-1 tablet:flex  flex-wrap justify-center`,
                }}>
                <FormSubHeading css={tw`mt-3 uppercase w-full text-center	`}>
                  Billing Info
                </FormSubHeading>
                {getFormSection(
                  <>
                    <PatternFormat
                      format={'1(###) ###-####'}
                      allowEmptyFormatting={true}
                      mask="_"
                      css={tw`w-[300px]	tablet:w-[220px] `}
                      title="Phone"
                      className="maxWidth"
                      onValueChange={(valueObj) => {
                        const { value } = valueObj;
                        setValue('phone', value);
                      }}
                      defaultValue={getDefaultIphone(paymentDetails?.phone)}
                      type="tel"
                      data-testid={testIds.phoneNumberInputID}
                      fullWidth
                      // message={errors?.phone?.message}
                      // error={errors?.phone?.message}
                      {...register('phone', {
                        required: 'Phone is required !',
                        pattern: {
                          value:
                            /\+?\d{1,9}?[-.\s]?\(?\d{1,9}?\)?[-.\s]?\d{1,9}[-.\s]?\d{1,9}[-.\s]?\d{1,9}/g,
                          message: 'Please enter valid Phone Number !',
                        },
                      })}
                      customInput={InputBase}
                    />
                    <InputBase
                      label="Search for an address"
                      fullWidth
                      inputComponent={
                        <AutocompleteAddress
                          label="Search for an address"
                          testId={testIds.searchAddressInputID}
                          // css={tw`w-[300px] max-w-[500px]`}
                          // className={style.autocomplete}
                          onSelectAddress={onSelectAddress}
                        />
                      }
                    />
                  </>,
                )}
                {getFormSection(
                  <>
                    <Controller
                      name={PaymentFieldNames.STREET_ADDRESS1}
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputBase
                          fullWidth
                          testId={testIds.streetAddressInputID}
                          onChange={onChange}
                          value={value}
                          disabled={true}
                          name={PaymentFieldNames.STREET_ADDRESS1}
                          label="Street Address"
                          // css={tw`w-[300px]	tablet:w-[470px] `}
                          defaultValue={paymentDetails?.streetAddress1}
                          message={error?.message}
                          errored={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name={PaymentFieldNames.STREET_ADDRESS2}
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputBase
                          fullWidth
                          onChange={onChange}
                          testId={testIds.apartmentInputID}
                          value={value}
                          name={PaymentFieldNames.STREET_ADDRESS2}
                          label="Apt, suite. (optional)"
                          // css={tw`w-[300px] tablet:w-[255px] `}
                          defaultValue={paymentDetails?.streetAddress2}
                          {...register('streetAddress2')}
                          message={error?.message}
                          errored={error?.message}
                        />
                      )}
                    />
                  </>,
                )}
                {getFormSection(
                  <>
                    <Controller
                      name="city"
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputBase
                          fullWidth
                          value={value}
                          testId={testIds.cityInputID}
                          onChange={onChange}
                          disabled={true}
                          label="city"
                          variant="filled"
                          // css={tw`w-[300px] tablet:w-[255px] `}
                          errored={!!error}
                          helperText={error ? error.message : null}
                          type="text"
                        />
                      )}
                    />
                    <InputBase
                      fullWidth
                      className={style.selectInputBase}
                      inputWrapperClassName={style.selectInputWrapperClassName}
                      disabled
                      inputComponent={
                        <StateSelectDropdown
                          testId={testIds.stateInputID}
                          deleteSelectSign={true}
                          disabled={true}
                          value={countryArea}
                          onChange={setCountryArea}
                        />
                      }
                    />
                    <Controller
                      name={PaymentFieldNames.POSTAL_CODE}
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputBase
                          fullWidth
                          testId={testIds.zipCodeInputID}
                          onChange={onChange}
                          value={value}
                          disabled={true}
                          name={PaymentFieldNames.POSTAL_CODE}
                          label="Zip Code"
                          // css={tw`w-[300px] tablet:w-[250px] `}
                          defaultValue={paymentDetails?.postalCode}
                          message={error?.message}
                          errored={error?.message}
                        />
                      )}
                    />
                  </>,
                )}
              </div>
            </>
          )}
        </div>
      </form>
    </Dialog>
  );
}
