import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import CardKnoxIField, { CARD_TYPE } from '@cardknox/react-ifields';
import {
  Button,
  Dialog,
  InputBase,
  Switch,
} from '@teddly/teddly-ui-components';
import styles from '@styles/form.module.scss';
import { CountryCode, DeliveryTypeEnum } from 'teddly-sdk';
import { useCheckoutShippingAddressContext } from '@context/CheckoutShippingAddressContext';
import { useCheckoutFlowContext } from '@context/CheckoutFlowContext';
import { IAddress } from 'teddly-sdk/lib/api/Checkout/types';
import tw from 'twin.macro';
import style from '../../components/style.module.scss';
import StateSelectDropdown from '../../components/StateSelectDropdown';
import AutocompleteAddress from '../../components/Address/AutocompleteAddress';
import { getDefaultIphone } from '@utils';
import { FormSubHeading } from '@components/Atoms/Typography';
import { PatternFormat } from 'react-number-format';
import {
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
} from '@config/validation';
import { TextField } from '@mui/material';
import { AddPaymentMethodDialogIDs } from '@cypress/components/dialogs/AddPaymentMethod.cy';

const emptyPaymentDetails = {
  cardNumber: '',
  cvv: '',
  expiryDate: '',
  firstName: '',
  lastName: '',
  city: '',
  streetAddress1: '',
  postalCode: '',
  streetAddress2: '',
  country: {
    code: 'US',
    country: 'United States of America',
  },
  countryArea: '',
  phone: '',
};

enum PaymentFieldNames {
  CARD_NUMBER = 'cardNumber',
  EXPIRY_DATE = 'expiryDate',
  CVV = 'cvv',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  STREET_ADDRESS1 = 'streetAddress1',
  STREET_ADDRESS2 = 'streetAddress2',
  POSTAL_CODE = 'postalCode',
}

export interface TokenData {
  xCardDataType: string;
  xIssuer: string;
  xMaskedData: string;
  xToken: string;
  xTokenCreationTime: string;
  xTokenType: string;
  xTokenVersion: string;
}

type InputType = typeof emptyPaymentDetails;

type PaymentDetailsProps = {
  paymentDetails?: InputType;
  onClose?: () => void;
  isOpen?: boolean;
  onSubmit?: (data: {
    token?: TokenData;
    exp: string;
    billingAddress?: IAddress;
  }) => void;
  loading: boolean;
  testIds: AddPaymentMethodDialogIDs;
};

export default function CardKnoxPaymentDetailsForm({
  paymentDetails,
  onClose,
  isOpen = false,
  onSubmit = () => null,
  loading = false,
  testIds,
}: PaymentDetailsProps) {
  const cardKnoxAccountConfig = {
    xKey: process.env.NEXT_PUBLIC_CARDKNOX_XKEY,
    xSoftwareVersion: process.env.NEXT_PUBLIC_CARDKNOX_XSOFTWAREVERSION,
    xSoftwareName: process.env.NEXT_PUBLIC_CARDKNOX_XSOFTWARENAME,
  };
  const cardKnoxInputStyles = {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '3px',
    width: '97%',
    height: '49px',
    margin: '0px',
  };

  const cardKnoxiframesStyles = document.querySelectorAll('iframe');
  cardKnoxiframesStyles.forEach((iframe) => {
    iframe.style.border = '0 solid black';
  });

  const { selectedShippingAddress, isCheckoutShippingAddressValid } =
    useCheckoutShippingAddressContext();
  const { checkout } = useCheckoutFlowContext();
  const [isAddressSame, setIsAddressSame] = useState(false);
  const [authToken, setAuthToken] = useState<TokenData>();
  const { register, handleSubmit, reset, setValue, control } =
    useForm<InputType>({ mode: 'onChange' });
  const [countryArea, setCountryArea] = useState(
    emptyPaymentDetails.countryArea,
  );

  const handleClose = () => {
    reset(emptyPaymentDetails);
    onClose();
  };

  const onSelectAddress = (selectAddressInput) => {
    setValue('postalCode', selectAddressInput.postalCode, {
      shouldValidate: true,
    });
    setValue('streetAddress1', selectAddressInput?.streetAddress1, {
      shouldValidate: true,
    });
    setValue('city', selectAddressInput.city, { shouldValidate: true });
  };

  const getFormSection = (section: JSX.Element, toLeft?: boolean) => (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: toLeft ? 'left' : 'center',
        gap: '1.5rem',
      }}>
      {section}
    </div>
  );

  const Submit = (data: InputType) => {
    const billingAddress = isAddressSame
      ? {
          firstName: selectedShippingAddress.firstName,
          lastName: selectedShippingAddress.lastName,
          city: selectedShippingAddress.city,
          streetAddress1: selectedShippingAddress.streetAddress1,
          postalCode: selectedShippingAddress.postalCode,
          streetAddress2: selectedShippingAddress.streetAddress2,
          phone: selectedShippingAddress?.phone,
          country: {
            code: CountryCode.US,
            country: 'United States of America',
          },
          countryArea: selectedShippingAddress.countryArea,
        }
      : {
          firstName: data.firstName,
          lastName: data.lastName,
          city: data.city,
          streetAddress1: data.streetAddress1,
          postalCode: data.postalCode,
          streetAddress2: data.streetAddress2,
          phone: data.phone,
          country: {
            code: CountryCode.US,
            country: 'United States of America',
          },
          countryArea,
        };
    onSubmit({
      token: authToken,
      exp: data.expiryDate.replace('/', ''),
      billingAddress: billingAddress,
    });

    reset(emptyPaymentDetails);
  };
  return (
    <Dialog
      isOpen={isOpen}
      title="Add Payment Method"
      onClose={handleClose}
      closeButtonTestId={testIds.closeBtnID}
      headerTestId={testIds.headerID}
      footerProps={{
        testId: testIds.footerID,
        children: [
          <>
            <Button
              disabled={
                loading || !authToken || (!isAddressSame && !countryArea)
              }
              loading={loading}
              onClick={handleSubmit(Submit)}
              title="Add Card"
              testId={testIds.addCardSubmitBtnIDs}
            />
          </>,
        ],
      }}>
      <form onSubmit={handleSubmit(Submit)}>
        <div
          style={{ maxWidth: '800px' }}
          css={{
            ...tw`gap-6 grid grid-cols-1 tablet:flex  flex-wrap justify-center	`,
          }}>
          {getFormSection(
            <>
              <Controller
                name={PaymentFieldNames.FIRST_NAME}
                control={control}
                rules={{
                  maxLength: {
                    value: FIRST_NAME_MAX_LENGTH,
                    message: `First name is more than ${FIRST_NAME_MAX_LENGTH} Characters`,
                  },
                  required: 'First name is required!',
                }}
                defaultValue={paymentDetails?.firstName}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputBase
                    fullWidth
                    testId={testIds.firstNameInputID}
                    onChange={onChange}
                    value={value}
                    name={PaymentFieldNames.FIRST_NAME}
                    label="First Name"
                    footnote={error?.message}
                    errored={error?.message}
                  />
                )}
              />
              <Controller
                name={PaymentFieldNames.LAST_NAME}
                control={control}
                rules={{
                  maxLength: {
                    value: LAST_NAME_MAX_LENGTH,
                    message: `Last name is more then ${LAST_NAME_MAX_LENGTH} Characters`,
                  },
                  required: 'Last name is required!',
                }}
                defaultValue={paymentDetails?.lastName}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputBase
                    fullWidth
                    testId={testIds.lastNameInputID}
                    onChange={onChange}
                    value={value}
                    name={PaymentFieldNames.LAST_NAME}
                    label="Last Name"
                    footnote={error?.message}
                    errored={error?.message}
                  />
                )}
              />
            </>,
          )}
          {getFormSection(
            <>
              <Controller
                name={PaymentFieldNames.CARD_NUMBER}
                control={control}
                defaultValue={paymentDetails?.cardNumber}
                render={({ field }) => (
                  <CardKnoxIField
                    data-testid={testIds.cardNumberInputID}
                    type={CARD_TYPE}
                    account={cardKnoxAccountConfig}
                    options={{
                      autoFormat: true,
                      autoFormatSeparator: ' ',
                      autoSubmit: true,
                      placeholder: '1234 1234 1234 1234',
                      iFieldstyle: cardKnoxInputStyles,
                    }}
                    onToken={(token) => setAuthToken(token)}
                  />
                )}
              />

              <Controller
                defaultValue={paymentDetails?.expiryDate}
                name={PaymentFieldNames.EXPIRY_DATE}
                control={control}
                rules={{
                  required: 'Expiry date is required !',
                }}
                render={({ field }) => (
                  <PatternFormat
                    {...field}
                    data-testid={testIds.expirationInputID}
                    format="##/##"
                    label={'MM/YY'}
                    title="Expiry"
                    css={tw`w-[300px]	tablet:w-[200px] `}
                    customInput={TextField}
                  />
                )}
              />
              <Controller
                name={PaymentFieldNames.CVV}
                control={control}
                defaultValue={paymentDetails?.cvv}
                rules={{
                  required: 'CVV is required !',
                }}
                render={({ field }) => (
                  <PatternFormat
                    {...field}
                    data-testid={testIds.cvvInputID}
                    format="####"
                    title="CVV"
                    label="CVV"
                    css={tw`w-[300px]	tablet:w-[200px] `}
                    customInput={TextField}
                  />
                )}
              />
            </>,
          )}

          {isCheckoutShippingAddressValid &&
            checkout?.deliveryType == DeliveryTypeEnum.SHIPPING && (
              <div className={styles.line}>
                <Switch
                  id="isAddressSame"
                  label="Billing address same as shipping"
                  testId={testIds.billingAddressToggleID}
                  checked={isAddressSame}
                  onChange={() => setIsAddressSame(!isAddressSame)}
                />
              </div>
            )}
          {(!isAddressSame || !isCheckoutShippingAddressValid) && (
            <>
              <div
                css={{
                  ...tw`gap-6 grid grid-cols-1 tablet:flex  flex-wrap justify-center`,
                }}>
                <FormSubHeading css={tw`mt-3 uppercase w-full text-center	`}>
                  Billing Info
                </FormSubHeading>
                {getFormSection(
                  <>
                    <PatternFormat
                      format={'1(###) ###-####'}
                      allowEmptyFormatting={true}
                      mask="_"
                      css={tw`w-[300px]	tablet:w-[220px] `}
                      title="Phone"
                      className="maxWidth"
                      onValueChange={(valueObj) => {
                        const { value } = valueObj;
                        setValue('phone', value);
                      }}
                      defaultValue={getDefaultIphone(paymentDetails?.phone)}
                      type="tel"
                      data-testid={testIds.phoneNumberInputID}
                      fullWidth
                      // message={errors?.phone?.message}
                      // error={errors?.phone?.message}
                      // {...register('phone', {
                      //   required: 'Phone is required !',
                      //   pattern: {
                      //     value:
                      //       /\+?\d{1,9}?[-.\s]?\(?\d{1,9}?\)?[-.\s]?\d{1,9}[-.\s]?\d{1,9}[-.\s]?\d{1,9}/g,
                      //     message: 'Please enter valid Phone Number !',
                      //   },
                      // })}
                      customInput={InputBase}
                    />
                    <InputBase
                      label="Search for an address"
                      fullWidth
                      inputComponent={
                        <AutocompleteAddress
                          label="Search for an address"
                          testId={testIds.searchAddressInputID}
                          // css={tw`w-[300px] max-w-[500px]`}
                          // className={style.autocomplete}
                          onSelectAddress={onSelectAddress}
                        />
                      }
                    />
                  </>,
                )}
                {getFormSection(
                  <>
                    <Controller
                      name={PaymentFieldNames.STREET_ADDRESS1}
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputBase
                          fullWidth
                          testId={testIds.streetAddressInputID}
                          onChange={onChange}
                          value={value}
                          disabled={true}
                          name={PaymentFieldNames.STREET_ADDRESS1}
                          label="Street Address"
                          // css={tw`w-[300px]	tablet:w-[470px] `}
                          defaultValue={paymentDetails?.streetAddress1}
                          message={error?.message}
                          errored={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name={PaymentFieldNames.STREET_ADDRESS2}
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputBase
                          fullWidth
                          onChange={onChange}
                          testId={testIds.apartmentInputID}
                          value={value}
                          name={PaymentFieldNames.STREET_ADDRESS2}
                          label="Apt, suite. (optional)"
                          // css={tw`w-[300px] tablet:w-[255px] `}
                          defaultValue={paymentDetails?.streetAddress2}
                          {...register('streetAddress2')}
                          message={error?.message}
                          errored={error?.message}
                        />
                      )}
                    />
                  </>,
                )}
                {getFormSection(
                  <>
                    <Controller
                      name="city"
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputBase
                          fullWidth
                          value={value}
                          testId={testIds.cityInputID}
                          onChange={onChange}
                          disabled={true}
                          label="city"
                          variant="filled"
                          // css={tw`w-[300px] tablet:w-[255px] `}
                          errored={!!error}
                          helperText={error ? error.message : null}
                          type="text"
                        />
                      )}
                    />
                    <InputBase
                      fullWidth
                      // label="state"
                      // placeholder={"state"}
                      className={style.selectInputBase}
                      inputWrapperClassName={style.selectInputWrapperClassName}
                      disabled
                      inputComponent={
                        <StateSelectDropdown
                          // data-testid={addressIds.stateInput}
                          testId={testIds.stateInputID}
                          deleteSelectSign={true}
                          disabled={true}
                          value={countryArea}
                          onChange={setCountryArea}
                        />
                      }
                    />
                    <Controller
                      name={PaymentFieldNames.POSTAL_CODE}
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputBase
                          fullWidth
                          testId={testIds.zipCodeInputID}
                          onChange={onChange}
                          value={value}
                          disabled={true}
                          name={PaymentFieldNames.POSTAL_CODE}
                          label="Zip Code"
                          // css={tw`w-[300px] tablet:w-[250px] `}
                          defaultValue={paymentDetails?.postalCode}
                          message={error?.message}
                          errored={error?.message}
                        />
                      )}
                    />
                  </>,
                )}
              </div>
            </>
          )}
        </div>
      </form>
    </Dialog>
  );
}
