import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useDisableScroll from '@hooks/useDisableScroll';
import {
  InputBase,
  Button,
  ButtonVariantEnum,
  Dialog,
} from '@teddly/teddly-ui-components';
import styles from '@styles/dialog.module.scss';
import { EMAIL_MAX_LENGTH } from '@config/validation';
import classNames from 'classnames';
import ForgotPasswordDialogIDs from '@cypress/components/dialogs/ForgotPassword.cy';

interface FormDataType {
  email?: string;
}

type Props = {
  forgotPassword?: (data: FormDataType) => Promise<void>;
  isOpen?: boolean;
  fieldErrorMessages?: {
    [key in keyof FormDataType]: string;
  };
  onClose?: () => void;
  onLoginClick?: () => void;
  testIds: ForgotPasswordDialogIDs;
};

const formId = 'forgot-password';

export default function ForgotPasswordDialog({
  forgotPassword,
  isOpen,
  fieldErrorMessages = {
    email: '',
  },
  onClose,
  onLoginClick,
  testIds,
}: Props): JSX.Element {
  useDisableScroll(isOpen);
  const [loading, setLoading] = useState(false);

  const openLogin = () => {
    onLoginClick();
    reset();
    setLoading(false);
  };
  const { handleSubmit, reset, control } = useForm<FormDataType>();

  const handleValidate = (value) => {
    if (value) {
      return true;
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await forgotPassword(data);
      reset();
    } catch (e) {
      alert('Something went wrong!');
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog
      title="Login to your account"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTestId={testIds.closeBtnID}
      headerTestId={testIds.headerID}>
      <Dialog.Body testId={testIds.bodyID}>
        <form
          id={formId}
          onSubmit={handleSubmit(onSubmit)}
          className={styles.dialogBodySectionsContainer}>
          <div
            className={classNames(
              styles.dialogTitle,
              styles.dialogBodySection,
            )}>
            <div data-testid={testIds.titleID} className={styles.dialogTitle}>
              Forgot your password?
            </div>
            <div className={styles.dialogBodySubTitle}>
              Fear not. We’ll email you instructions to reset your password.
              Email. We’ll email you a password reset link.
            </div>
          </div>
          <div className={styles.dialogBodySection}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                maxLength: {
                  value: EMAIL_MAX_LENGTH,
                  message: `email is more then ${EMAIL_MAX_LENGTH} Characters`,
                },
                required: 'Email is required !',
                validate: (value) => handleValidate(value),
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Please enter valid Email !',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputBase
                  data-testid={testIds.emailInputID}
                  stretch
                  type="text"
                  label="Email address"
                  view={false}
                  value={value}
                  fullWidth
                  onChange={onChange}
                  className="maxWidth"
                  footnote={
                    fieldErrorMessages.email ? (
                      <span style={{ color: '#ef2d2d' }}>
                        {fieldErrorMessages.email}
                      </span>
                    ) : error?.message.length > 0 ? (
                      error?.message
                    ) : (
                      <span></span>
                    )
                  }
                  errored={error?.message?.length > 0}
                />
              )}
            />
          </div>
        </form>
      </Dialog.Body>
      <Dialog.Footer testId={testIds.footerID}>
        <Button
          data-testid={testIds.backToLoginBtnID}
          title="Return to login"
          onClick={openLogin}
          variant={ButtonVariantEnum.NEGATIVE}
        />
        <Button
          data-testid={testIds.forgotPasswordSubmitBtnID}
          form={formId}
          type="submit"
          className="darkBackgroundColor"
          disabled={!!loading}
          loading={loading}
          title="Reset Password"
        />
      </Dialog.Footer>
    </Dialog>
  );
}
